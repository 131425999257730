import { Injectable } from "@angular/core";
import { UserManager } from "oidc-client-ts";
import { environment } from "../../../environments/environment";
import { UrlResolverService } from "./helpers/url-resolver.service";
import { Person } from "../models/persons/person";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _userManager: UserManager;

    public user?: Person;

    constructor(
        private readonly _urlResolver: UrlResolverService,
        private readonly _http: HttpClient
    ) {
        this._userManager = this.createUserManager();
    }

    private createUserManager(tenantId?: number): UserManager {
        return new UserManager({
            authority: environment.authUrl,
            loadUserInfo: true,
            scope: 'admin_app',
            client_id: 'admin-app',
            redirect_uri: window.location.origin + '/auth/login-redirect',
            post_logout_redirect_uri: window.location.origin + '/auth/sign-out',
            response_type: 'code',
            extraTokenParams: {
                'acr_values': tenantId
                    ? `tenantId:${tenantId}`
                    : ''
            }
        });
    }

    public async getAuthState(): Promise<AuthState> {
        return new Promise<AuthState>(async (resolve, reject) => {
            if (window.location.href.indexOf('auth/login-redirect') >= 0) {
                return resolve(AuthState.LoggedIn);
            }

            try {
                if (await this.isAuthenticated()) {
                    return resolve(AuthState.LoggedIn);
                }

                return resolve(AuthState.LoggedOut);

            } catch (e) {
                return reject(e);
            }
        });
    }

    public async getTenantId(): Promise<number | null> {
        const user = await this._userManager.getUser();

        if (user) {
            const profile = user.profile as any;

            if (profile.tenId) {
                return parseInt(profile.tenId);
            }
        }

        return null;
    }

    public async isAuthenticated(): Promise<boolean> {
        const user = await this._userManager.getUser();
        return !!user;
    }

    public signIn(tenantId?: number): Promise<void> {
        if (tenantId) {
            this._userManager = this.createUserManager(tenantId);
        }

        return this._userManager.signinRedirect();
    }

    public signOut(): void {
        this._userManager.signoutRedirect();
    }

    public async signInCallback(): Promise<void> {
        await this._userManager.signinCallback();
    }

    public navigateToProfile(): void {
        window.open(`${environment.authUrl}/Identity/Account/Manage`, '_blank');
    }

    public async getAccessToken(): Promise<string | undefined> {
        const user = await this._userManager.getUser();
        return user?.access_token;
    }

    public async loadUserData(): Promise<void> {
        const url = this._urlResolver.resolveUrl('api/users/my');
        const observable = this._http.get(url);

        this.user = await lastValueFrom(observable);
    }
}

export enum AuthState {
    LoggingIn,
    LoggedIn,
    LoggedOut
}